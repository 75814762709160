import React, { useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BackButton from '../../../Components/BackToPrev';
import { useNavigate } from 'react-router-dom';
import AirportListButton from '../../../Components/Airport/AirportListButton';
import { constants } from '../../../lib/constants';
import {
  addDestination,
  addOutletServices,
} from '../../../Services/admin-service';
import OutletList from '../../../Components/Outlet/OutletList';

const AddOutlet = () => {
  const navigate = useNavigate();
  const [placeSelected, setPlaceSelected] = useState();
  const [submiting, setSubmiting] = useState(false);

  const handleSubmit = () => {
    setSubmiting(true);
    if (placeSelected !== undefined) {
      const payload = {
        name: placeSelected.name,
        address: {
          street: '',
          city: '',
          state: '',
          country: '',
        },
        location: {
          type: 'Point',
          coordinates: [
            placeSelected.geometry.location.lng(),
            placeSelected.geometry.location.lat(),
          ],
        },
        contact: placeSelected.formatted_phone_number || '',
      };
      placeSelected.address_components.forEach((val) => {
        if (val.types[0] === 'locality') payload.address.street = val.long_name;
        if (val.types[0] === 'administrative_area_level_3')
          payload.address.city = val.long_name;
        else if (val.types[0] === 'administrative_area_level_1')
          payload.address.state = val.long_name;
        else if (val.types[0] === 'country')
          payload.address.country = val.long_name;
      });

      addOutletServices(payload)
        .then((res) => {
          toast.success('Outlet Added', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setPlaceSelected(undefined); // Clear the placeSelected state
          navigate('/outletListing');
        })
        .catch((err) => {
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log(err);
        })
        .finally(() => {
          setSubmiting(false);
        });
    } else {
      toast.error('Please select Outlet', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const airportSelected = (data) => {
    setPlaceSelected(data);
  };

  return (
    <>
      <ToastContainer />
      <div className="">
        <div className="top-header">
          <div className="page-name">
            <BackButton />
            <h4>Add Outlet</h4>
          </div>
          <div className="import-export">
            <AirportListButton text="Outlet Listing" route="outletListing" />
          </div>
        </div>

        <div className="serachbar-box">
          <div className="searchbar">
            <Autocomplete
              className="border-l p-2 px-4 w-100 rounded-3"
              apiKey={constants.GOOGLE_API}
              onPlaceSelected={airportSelected}
              debounce={500}
              libraries={['places']}
              options={{
                types: [],
                fields: [
                  'address_components',
                  'formatted_phone_number',
                  'name',
                  'vicinity',
                  'website',
                  'type',
                  'geometry',
                ],
              }}
            />
          </div>
          <button
            className="btn btn-secondary"
            onClick={handleSubmit}
            disabled={submiting}
            aria-disabled={submiting}
          >
            {submiting ? 'Saving...' : 'Add Outlet'}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddOutlet;
