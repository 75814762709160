import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  acceptWithdrawalRequest,
  getWithdrawalRequest,
  rejectWithdrawalRequest,
} from '../../Services/admin-service';
import { ToastContainer, toast } from 'react-toastify';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { capitalizeFirstLetter } from '../../utils/helpers';

const WIthdrawalList = (props) => {
  const [currentPage, setcurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    loadData(currentPage, search, status);
  }, [currentPage, search, status]);

  const loadData = (currentPage, search, status) => {
    getWithdrawalRequest({ page: currentPage, status })
      .then((res) => {
        setWithdrawalList(res.data.docs);
        setTotalPages(res.data.totalPages);
        setcurrentPage(res.data.page);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log(err);
      });
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setcurrentPage(1);
  };

  const completeWithdrawal = (id) => {
    const confirm = window.confirm('Are you sure you want to accept this?');
    if (confirm === false) return;

    acceptWithdrawalRequest({ withdrawalId: id })
      .then((res) => {
        toast.success('Withdrawal Request Accepted', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        loadData(currentPage, search);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log(err);
      });
  };
  const rejectWithdrawal = (id) => {
    const confirm = window.confirm('Are you sure you want to reject this?');
    if (confirm === false) return;

    rejectWithdrawalRequest({ withdrawalId: id })
      .then((res) => {
        toast.success('Withdrawal Request Rejected', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        loadData(currentPage, search);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log(err);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.status === 'pending' ? (
          <>
            <Button
              variant="success me-2"
              onClick={() => completeWithdrawal(rowData._id)}
            >
              Accepted
            </Button>
            <Button
              variant="danger"
              onClick={() => rejectWithdrawal(rowData._id)}
            >
              Rejected
            </Button>
          </>
        ) : (
          <Button
            variant={`${
              rowData.status === 'completed' ? 'success' : 'danger'
            } me-2`}
            aria-disabled={true}
            disabled={true}
          >
            {capitalizeFirstLetter(rowData.status)}
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <div className="serachbar-box">
        <ToastContainer />

        <div className="searchbar">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">
              <AiOutlineSearch />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search Customers"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={search}
              onChange={handleSearch}
            />
          </InputGroup>
        </div>
        <button className="btn btn-secondary" onClick={handleSearch}>
          Search
        </button>
        <DropdownButton
          key="Info"
          id={`dropdown-variants-Info`}
          variant="secondary"
          title={(status && capitalizeFirstLetter(status)) || 'Status'}
          onSelect={(eventKey) => setStatus(eventKey)}
        >
          {['completed', 'pending', 'rejected'].map((stat, index) => (
            <Dropdown.Item eventKey={stat} key={index}>
              {capitalizeFirstLetter(stat)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      <DataTable value={withdrawalList} tableStyle={{ width: '100%' }}>
        <Column field="userId.firstName" header="Name"></Column>
        <Column field="userId.mobileNumber" header="Mobile Number"></Column>
        <Column field="amount" header="Amount"></Column>
        <Column field="status" header="status"></Column>
        <Column
          header="Action"
          exportable={false}
          body={actionBodyTemplate}
          style={{ minWidth: '12rem' }}
        ></Column>
      </DataTable>
      <div className="custom-pagination justify-content-between d-flex align-items-center py-4">
        <button
          className="btn btn-primary me-2"
          onClick={() => handlePageChange(+currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="rounded-pill text-md">{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          className="btn btn-primary ms-2"
          onClick={() => handlePageChange(+currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default WIthdrawalList;
