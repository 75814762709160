import React, {useState} from "react";
import Autocomplete from "react-google-autocomplete";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import BackButton from '../../../Components/BackToPrev'
import { useNavigate } from "react-router-dom";
import AirportListButton from "../../../Components/Airport/AirportListButton";
import { constants } from "../../../lib/constants";
import { addDestination } from "../../../Services/admin-service";

const AddAirport = () => {

    const navigate = useNavigate();
    const [placeSelected,setPlaceSelected] = useState();


    const handleSubmit = () => {
        console.log(placeSelected)
        if(placeSelected != undefined){
            const payload = {
                locationName: placeSelected.formatted_address,
                address: placeSelected.formatted_address,
                lat: placeSelected.geometry.location.lat(),
                long: placeSelected.geometry.location.lng(),
                city: "",
                state: "",
                country: "",
                type: "airport",
            }
            placeSelected.address_components.forEach(val => {
                if(val.types[0] == "locality")
                    payload.city = val.long_name
                else if(val.types[0] == "administrative_area_level_1")
                    payload.state = val.long_name
                else if(val.types[0] == "country")
                    payload.country = val.long_name
            })

            addDestination(payload)
            .then((res) => {
                toast.success("Airport Added", {
                    position: toast.POSITION.BOTTOM_RIGHT
                  });
                
            }).catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                  });
                console.log(err)
            })            

        }else{
            toast.error("Please select Airport", {
                position: toast.POSITION.BOTTOM_RIGHT
              });
        }
            
    }

    const airportSelected = (data) => {               
        setPlaceSelected(data)   
    }


    return (
        <>
        <ToastContainer />
            <div className=''>
                <div className='top-header'>
                    <div className='page-name'>
                        <BackButton />
                        <h4>Add Airport</h4>
                    </div>
                    <div className='import-export'>
                        <AirportListButton text="Airport Listing" route="airportListing" />
                    </div>
                </div>

                <div className="serachbar-box">
                    <div className="searchbar">
                    <Autocomplete className="border-l p-2 px-4 w-100 rounded-3" onChange={e => {setPlaceSelected()}} apiKey={constants.GOOGLE_API} onPlaceSelected={airportSelected} />
                    </div>
                    <button className="btn btn-secondary" onClick={handleSubmit}>Add Airport</button>
                </div>
            </div>
        </>
    )
}

export default AddAirport