import React from 'react'
import BackButton from '../../../Components/BackToPrev'
import WIthdrawalList from '../../../Components/Withdrawal/WIthdrawalList'

const Withdraw = () => {
    return (
        <div className=''>
            <div className='top-header'>
                <div className='page-name'>
                    <BackButton />
                    <h4>Withdraw Requests</h4>
                </div>
            </div>

            <WIthdrawalList />
        </div>
    )
}

export default Withdraw
