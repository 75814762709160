import React, {useState} from "react"
import { useParams } from "react-router-dom";
import BackButton from "../../../Components/BackToPrev"

import DriverDocs from "../../../Components/Driver/DriverDocs";

const ViewDriverDocs = (props) => {
    const params = useParams();
    const driverId = params.id


    return (
        <>
            <div className='users-page'>
                <div className='top-header'>
                    <div className='page-name'>
                        <BackButton />
                        <h4>Verify Driver Documents</h4>
                    </div>
                </div>

                <DriverDocs driverId={driverId}/>
                
            </div>
        </>
    )
}


export default ViewDriverDocs