import { useEffect } from 'react';
import '../src/Css/style.css';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getLocalStorageItem } from './utils/localStorage';
import SuperAdminLayout from './Components/Layout/SuperAdminLayout';
import Login from './Pages/SuperAdminPages/Authentication/login';
import Users from './Pages/SuperAdminPages/Users';
import AddAirport from './Pages/SuperAdminPages/Airport/AddAiport';
import Driver from './Pages/SuperAdminPages/Drivers/Driver';
import ViewDriverDocs from './Pages/SuperAdminPages/Drivers/ViewDriverDocs';
import AirportListing from './Pages/SuperAdminPages/Airport/AirportListing';
import AddCity from './Pages/SuperAdminPages/City/AddCity';
import CityListing from './Pages/SuperAdminPages/City/CityListing';
import AddFair from './Pages/SuperAdminPages/Fair/AddFair';
import FairListing from './Pages/SuperAdminPages/Fair/FairListing';
import AddOutlet from './Pages/SuperAdminPages/Outlet/AddOutlet';
import OutletListing from './Pages/SuperAdminPages/Outlet/OutletListing';
import Withdraw from './Pages/SuperAdminPages/Withdrwals';

function AppRouter() {
  const history = useNavigate();
  const user_token = getLocalStorageItem('token');
  const isAuthenticated = user_token != null ? true : false;
  useEffect(() => {
    document.body.setAttribute('data-page', window?.location?.pathname);
  }, [history]);

  return (
    <>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route element={<SuperAdminLayout />}>
              <Route exact="true" path="/driverList" element={<Driver />} />
              <Route
                exact="true"
                path="/driverdocs/:id"
                element={<ViewDriverDocs />}
              />
              <Route
                exact="true"
                path="/airportListing"
                element={<AirportListing />}
              />
              <Route
                exact="true"
                path="/outletListing"
                element={<OutletListing />}
              />
              <Route exact="true" path="/addOutlet" element={<AddOutlet />} />
              <Route
                exact="true"
                path="/outletListing"
                element={<OutletListing />}
              />
              <Route exact="true" path="/addAirport" element={<AddAirport />} />
              <Route
                exact="true"
                path="/cityListing"
                element={<CityListing />}
              />
              <Route exact="true" path="/addCity" element={<AddCity />} />
              <Route
                exact="true"
                path="/fairListing"
                element={<FairListing />}
              />
              <Route exact="true" path="/edit-fair/:id" element={<AddFair />} />
              <Route exact="true" path="/addFair" element={<AddFair />} />
              <Route
                exact="true"
                path="/withdrawal-list"
                element={<Withdraw />}
              />

              <Route
                path="*"
                element={<Navigate to="/driverList" replace={true} />}
              />
            </Route>
          </>
        ) : (
          <>
            <Route exact="true" path="/login" element={<Login />} />

            <Route path="*" element={<Navigate to="/login" replace={true} />} />
          </>
        )}
      </Routes>
      <ToastContainer />
    </>
  );
}

export default AppRouter;
