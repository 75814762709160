import React from 'react';
import { Pagination } from 'antd';

const Paginate = ({ rows, total, onChange }) => {
  return (
    <>
      <Pagination
        className="pagination-rule"
        showQuickJumper
        defaultCurrent={rows}
        total={total}
        onChange={onChange}
      />
    </>
  );
};

export default Paginate;
