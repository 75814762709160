import React from "react";
import { deleteFair } from "../../Services/admin-service";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const DeleteFair = (props) => {

    const fairId = props.fairId

    const handleClick = () => {

        const payload = {
            fairId
        }

        deleteFair(payload)
            .then((res) => {
                const nowDocs = props.listVal.filter(val => val._id != fairId)
                props.onClickDelete(nowDocs)
                toast.success("Fair Deleted", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

            })
    }

    return (
        <>
            <ToastContainer />
            <button className="btn btn-danger" onClick={handleClick}>Delete</button>
        </>
    )
}

export default DeleteFair