import BackButton from "../../../Components/BackToPrev"
import CityList from "../../../Components/City/CityList"

const CityListing = () => {


    return (
        <>
            <div className=''>
                <div className='top-header'>
                    <div className='page-name'>
                        <BackButton />
                        <h4>City Listing</h4>
                    </div>
                </div>
                <CityList />
            </div>
        </>
    )
}

export default CityListing