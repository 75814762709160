import React from 'react';
import { useNavigate } from 'react-router-dom';

const ViewDocumentButton = (props) => {
  const navigate = useNavigate();

  const verifyDriverDocument = () => {
    
  navigate(`/driverdocs/${props.driverId}`, { replace: true });
  }

  return (
    <button
        title="View Documents"
        className='btn btn-outline-danger'
        onClick={verifyDriverDocument}
    >
        View Documents
    </button>
  );
};

export default ViewDocumentButton;
