import React from "react";
import { useNavigate } from "react-router-dom";

const AirportListButton = ({text, route}) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/${route}`, { replace: true });
    }

    return (
        <>
        <button onClick={handleClick}>{text}</button>
        </>
    )
}

export default AirportListButton