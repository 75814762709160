import React from 'react'
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import { BiMoney, BiUserCircle } from "react-icons/bi";
import { BsUnindent, BsFillAirplaneFill, BsFillPinMapFill, BsYelp } from "react-icons/bs";
import { removeLocalStorageItem } from '../../../utils/localStorage';
import { FaCodePullRequest } from "react-icons/fa6";


const Superadminsidebar = () => {

  const handleLogout = () => {
    try {
      removeLocalStorageItem('token')
    } catch (error) {
      console.log("Error while logging out!")
    }
  }

  return (
    <>
      <div className="Sidebarlayout">
        <Navbar className="mainnavbar">
          <h4 className="navbar-title">General</h4>
          <Nav className="mb-5">
            <NavLink onClick={() => document.body.classList.toggle("sidebaractive")} to='/driverList'>< BiUserCircle />Driver List</NavLink>
            <NavLink onClick={() => document.body.classList.toggle("sidebaractive")} to='/addAirport'><BsFillAirplaneFill />Add Airport</NavLink>
            <NavLink onClick={() => document.body.classList.toggle("sidebaractive")} to='/addOutlet'><BsYelp />Add Outlet</NavLink>
            <NavLink onClick={() => document.body.classList.toggle("sidebaractive")} to='/addCity'><BsFillPinMapFill /> Add City</NavLink>
            <NavLink onClick={() => document.body.classList.toggle("sidebaractive")} to='/addFair'><BiMoney /> Add Fair</NavLink>
            <NavLink onClick={() => document.body.classList.toggle("sidebaractive")} to='/withdrawal-list'><FaCodePullRequest /> Withdraw Requests</NavLink>
          </Nav>
        </Navbar>
        <Navbar className="mainnavbar logoutbar">
          <NavLink to='/' onClick={handleLogout}><BsUnindent />Logout</NavLink>
        </Navbar>
      </div>
    </>
  )
}

export default Superadminsidebar