import { useState, useEffect } from 'react'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { getDestinationListing } from '../../Services/admin-service'


const SelectDestination = (props) => {

    const [destList, setDestList] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const payload = { type: props.destType }
        setSelectedOption(null);
        loadData(payload)
    }, [props.destType,props.formSubmitted])

    const loadData = (type) => {
        getDestinationListing(type)
            .then((res) => {
                const data = res.data.list.map((val) => { return { value: val._id, label: val.locationName } });
                setDestList(data)
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
    }

    const handleSelect = (e) => {
        setSelectedOption(e);
        props.onClickSelect(e.value)
    }

    return (
        <>
        <ToastContainer />
            <Select  onChange={handleSelect} value={selectedOption} options={destList} />
        </>
    )
}

export default SelectDestination