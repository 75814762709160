import React, { useState, useEffect } from "react";

import BackButton from '../../../Components/BackToPrev'
import DriverList from "../../../Components/Driver/DriverList";


const Driver = (props) => {

    return (
        <>
            <div className=''>
                <div className='top-header'>
                    <div className='page-name'>
                        <BackButton />
                        <h4>Drivers List</h4>
                    </div>
                </div>

                <DriverList />                
            </div>
        </>
    )
}

export default Driver