import { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Lightbox } from "react-modal-image";

import { getDriverDocs } from "../../Services/admin-service";
import RejectDoc from "../../Components/Driver/RejectDoc";
import AcceptDoc from "../../Components/Driver/AcceptDoc"


const DriverDocs = (props) => {

    const [driverDocs, setDriverDocs] = useState([])
    const driverId = props.driverId
    const [docType, setDocType] = useState('pending')
    const [isOpen, setIsOpen] = useState(false);
    const [imgName, setImgName] = useState('');

    useEffect(() => {
        const payload = {
            driverId,
            type: docType
        }
        loadData(payload)

    }, [driverId, docType])

    const loadData = (payload) => {
        getDriverDocs(payload)
            .then((res) => {
                setDriverDocs(res.data.result)
            }).catch((err) => {
                console.log(err)
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
    }

    const renderActions = (rowData) => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <AcceptDoc onSetDriverDoc={setDriverDocs} docVals={driverDocs} docId={rowData._id} /><RejectDoc onSetDriverDoc={setDriverDocs} docVals={driverDocs} docId={rowData._id} />
                <button className="btn btn-secondary ms-2" type="button" onClick={handleOpenImage} title={rowData.fileName} >Open Image</button>
            </div>
        );
    };

    const imageTemplate = (rowData) => {
        return (
            <img src={rowData.fileName} title="Open image" alt="Driver" width="60" height="60" onClick={handleOpenImage} />
        )
    }
    const handleTypeChange = (e) => {
        setDocType(e.target.value)
    }

    const handleOpenImage = (e) => {
        if(e.currentTarget.type == "button")
            setImgName(e.currentTarget.title)
        else
            setImgName(e.currentTarget.src)
        setIsOpen(true)
    }
    const closeLightbox = () => {
        setIsOpen(false)
    }


    return (
        <>
            <ToastContainer />
            {isOpen && <Lightbox
                medium={imgName}
                large={imgName}
                alt="Driver Document"
                onClose={closeLightbox}
            />}
            <div className="p-3 bg-light mt-4">
                <h5>Document Status</h5>
                <label className="ms-2"><input type="radio" onClick={handleTypeChange} name="docType" id="pendingType" value="pending" defaultChecked />&nbsp; Pending</label>
                <label className="ms-2"><input type="radio" onClick={handleTypeChange} name="docType" id="acceptedType" value="accepted" />&nbsp; Accepted</label>
                <label className="ms-2"><input type="radio" onClick={handleTypeChange} name="docType" id="rejectedType" value="rejected" />&nbsp; Rejected</label>
            </div>
            <DataTable value={driverDocs} tableStyle={{ width: '100%' }}>
                <Column header="Doc Image" body={imageTemplate} ></Column>
                <Column field="documentType" header="Name"></Column>
                {docType == 'pending' && <Column header="Action" body={renderActions}></Column>}
            </DataTable>
        </>
    )
}

export default DriverDocs