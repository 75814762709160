import React from "react";
import { useNavigate } from "react-router-dom";
import { verifyDriverDoc } from "../../Services/admin-service";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const RejectDoc = (props) => {

    const documentId = props.docId
    const navigate = useNavigate();

    const handleClick = () => {

        const payload = {
            documentId,
            action: "reject"
        }

        verifyDriverDoc(payload)
            .then((res) => {
                const nowDocs = props.docVals.filter(val => val._id != documentId)
                props.onSetDriverDoc(nowDocs)
                toast.error("Driver Document Rejected", {
                    position: toast.POSITION.BOTTOM_RIGHT
                  });
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                  });
                console.log(err)
            })
    }

    return (
        <>
        <ToastContainer />
            <button className="btn btn-secondary ms-2" onClick={handleClick}>Reject</button>
        </>
    )
}

export default RejectDoc