import BackButton from "../../../Components/BackToPrev"
import AirportList from "../../../Components/Airport/AirportList"

const AirportListing = () => {


    return (
        <>
            <div className=''>
                <div className='top-header'>
                    <div className='page-name'>
                        <BackButton />
                        <h4>Airport Listing</h4>
                    </div>
                </div>
                <AirportList />
            </div>
        </>
    )
}

export default AirportListing