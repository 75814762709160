import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import BackButton from "../../../Components/BackToPrev"
import FairListButton from "../../../Components/Fair/FairListButton";
import AddFairForm from "../../../Components/Fair/AddFairForm";


const ViewDriverDocs = (props) => {
    const [isEdit, setIsEdit] = useState(false)
    

    return (
        <>
            <div className='users-page'>
                <div className='top-header'>
                    <div className='page-name'>
                        <BackButton />
                        <h4>Fair</h4>
                    </div>
                    <div className='import-export'>
                        <FairListButton />
                    </div>
                </div>
                <AddFairForm />

            </div>
        </>
    )
}


export default ViewDriverDocs