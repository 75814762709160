import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  deleteOutletServices,
  getOutletServices,
} from '../../Services/admin-service';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Paginate from '../Pagination';
import { Form, InputGroup } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import { debounce } from '../../utils/helpers';

const OutletList = () => {
  const [outletList, setOutletList] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setSubmitted] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const payload = { page: first, limit: rows, search: search };
    debounceHandler(payload);
  }, [rows, first, search]);

  const debounceHandler = debounce((value) => {
    loadData({ page: first, limit: rows, search: search });
  }, 500);
  const loadData = (payload) => {
    setLoading(true);
    getOutletServices(payload)
      .then((res) => {
        setOutletList(res.data.docs);
        setTotalRecords(res.data.totalDocs);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    setSubmitted(true);
    setDeletingId(id);

    deleteOutletServices(id)
      .then((res) => {
        toast.success('Outlet Deleted', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        loadData();
      })
      .catch((error) => {
        console.log(error, 'error while deleting outlet');
        toast.success('Outlet Deleted failed', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setSubmitted(false);
        setDeletingId(null);
      });
  };

  const renderActions = (rowData) => {
    return (
      <button
        className="btn btn-secondary"
        onClick={() => handleDelete(rowData._id)}
        disabled={isSubmitting && deletingId === rowData._id} // Disable only the specific button
      >
        {isSubmitting && deletingId === rowData._id ? 'Deleting...' : 'Delete'}
      </button>
    );
  };
  const onPageChange = (page) => {
    setFirst(page);
  };

  return (
    <>
      <ToastContainer />

      <div className="searchbar mt-3">
        <InputGroup>
          <InputGroup.Text id="basic-addon1">
            <AiOutlineSearch />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search Customers"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      </div>

      <DataTable
        className="mt-4"
        value={outletList}
        tableStyle={{ minWidth: '100%' }}
      >
        <Column field="name" header="Outlet Name"></Column>
        <Column field="address.street" header="Street"></Column>
        <Column field="address.city" header="City"></Column>
        <Column field="address.state" header="State"></Column>
        <Column field="address.country" header="Country"></Column>
        <Column field="contact" header="Contact"></Column>
        <Column header="Action" body={renderActions}></Column>
      </DataTable>
      <Paginate rows={rows} total={totalRecords} onChange={onPageChange} />
    </>
  );
};

export default OutletList;
