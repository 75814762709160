import { useState } from "react"
import { login } from "../../Services/auth-service"
import { setLocalStorageItem } from "../../utils/localStorage"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const LoginForm = () => {

    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")

    const navigate = useNavigate()

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            if(email && password)
            {
                const payload = {email,password}
                const resp = await login(payload)

                setLocalStorageItem('token',resp.data.accessToken)
                navigate('/driverList')
                
            }
            else
            {
                toast.error("Enter all fields", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            
            
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_RIGHT
              });
            console.log(error)
        }
    }

    return(
        <>
        <ToastContainer />
        <form onSubmit={handleSubmit} className="d-flex flex-column p-5 rounded-5 bg-white shadow minwidth">
            <div className="d-flex flex-column py-2">
            <label className="py-1 text-md" htmlFor="email">Email:</label>
            <input className="rounded-pill  py-3 px-3" type="text" onChange={(e) => {setEmail(e.target.value);}} placeholder="Enter Email here"></input>
            </div>
            <div className="d-flex flex-column py-2">
            <label className="text-md py-1" htmlFor="email">Password:</label>
            <input className="rounded-pill  py-3 px-3" type="password" onChange={(e) => {setPassword(e.target.value);}} placeholder="Enter Password here"></input>
            </div>
            <button className="btn btn-primary my-2">Submit</button>
        </form>
        </>
    )
}

export default LoginForm