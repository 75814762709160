import { useState, useEffect } from "react"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import { getFairList } from "../../Services/admin-service";
import { constants } from "../../lib/constants";
import DeleteFair from "./DeleteFair";
import EditFair from "./EditFair";

const FairList = () => {

    const [fairList, setFairList] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        getFairList()
            .then((res) => {
                setFairList(res.data.list)
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
    }

    const renderActions = (rowData) => {
        return (
            <div className="d-flex align-items-center">
                <DeleteFair listVal={fairList} onClickDelete={setFairList} fairId={rowData._id} />
                <EditFair fairId={rowData._id}/>
            </div>
        );
    };


    return (
        <>
        <ToastContainer />
            <DataTable className="mt-4 overflow-scroll" value={fairList} tableStyle={{ width: "100%" }}>
                <Column field="fromDestinationId.locationName" header="From"></Column>
                <Column field="toDestinationId.locationName" header="To"></Column>
                <Column header="Type" field="type"></Column>
                <Column field="amount" header="Amount"></Column>
                <Column field="discount" header="Discount"></Column>
                <Column field="finalAmount" header="Final Amount"></Column>
                <Column header="Action" body={renderActions}></Column>
            </DataTable>
        </>
    )
}

export default FairList