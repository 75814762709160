import { useState, useEffect } from "react"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDestinationListing } from "../../Services/admin-service";
import DeleteDestination from "../Destination/DeleteDestination";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const AirportList = () => {

    const [airportList, setAirportList] = useState([])

    useEffect(() => {
        const payload = { type: "airport" }
        loadData(payload);
        // loadData()
    }, [])

    const loadData = (type) => {
        getDestinationListing(type)
            .then((res) => {
                setAirportList(res.data.list)
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
    }

    const renderActions = (rowData) => {
        return (
            <div><DeleteDestination listVal={airportList} onClickDelete={setAirportList} destId={rowData._id} /></div>
        )
    }

    return (
        <>
            <ToastContainer />
            <DataTable className="mt-4" value={airportList} tableStyle={{ width: '100%' }}>
                <Column field="locationName" header="Airport Name"></Column>
                <Column field="city" header="City"></Column>
                <Column field="state" header="State"></Column>
                <Column header="Action" body={renderActions}></Column>
            </DataTable>
        </>
    )
}


export default AirportList