import React from 'react';
import { useNavigate } from 'react-router-dom';

const EditFair = (props) => {
  const navigate = useNavigate();

  const editFair = () => {
  navigate(`/edit-fair/${props.fairId}`, { replace: true });
  }

  return (
    <button
        title="Edit Fair"
        onClick={editFair}
        className='btn btn-secondary ms-2'
    >
        Edit
    </button>
  );
};

export default EditFair;
