import { endpoint } from '../Config/endpoints';
import { sendRequest } from './http-service';

export const getDriverList = (pageNumber, payload) => {
  return sendRequest(
    endpoint.admin.GET_DRIVERS_LIST + pageNumber,
    'POST',
    payload
  );
};

export const getDriverDocs = (payload) => {
  return sendRequest(endpoint.admin.GET_DRIVER_DOCS, 'POST', payload);
};

export const verifyDriverDoc = (payload) => {
  return sendRequest(endpoint.admin.VERIFY_DRIVER_DOC, 'POST', payload);
};

export const addDestination = (payload) => {
  return sendRequest(endpoint.admin.ADD_DESTINATION, 'POST', payload);
};

export const getDestinationListing = (payload) => {
  return sendRequest(endpoint.admin.GET_CITY_LISTING, 'GET', payload);
};

export const deleteDestination = (payload) => {
  return sendRequest(endpoint.admin.DELETE_DESTINATION, 'POST', payload);
};

export const addFair = (payload) => {
  return sendRequest(endpoint.admin.ADD_FAIR, 'POST', payload);
};

export const getFairList = (payload = null) => {
  return sendRequest(endpoint.admin.GET_FAIR_LIST, 'GET', payload);
};

export const deleteFair = (payload) => {
  return sendRequest(endpoint.admin.DELETE_FAIR, 'POST', payload);
};

export const getFairData = (payload) => {
  return sendRequest(endpoint.admin.GET_FAIR_DATA, 'GET', payload);
};

export const editFairData = (payload) => {
  return sendRequest(endpoint.admin.EDIT_FAIR_DATA, 'POST', payload);
};

export const getWithdrawalRequest = (payload) => {
  return sendRequest(endpoint.admin.WITHDRAWAL_LIST, 'GET', payload);
};

export const acceptWithdrawalRequest = (payload) => {
  console.log('🚀 ~ acceptWithdrawalRequest ~ payload:', payload);
  return sendRequest(endpoint.admin.ACCEPT_WITHDRAWAL, 'POST', payload);
};

export const rejectWithdrawalRequest = (payload) => {
  return sendRequest(endpoint.admin.REJECT_WITHDRAWAL, 'POST', payload);
};
export const addOutletServices = (payload) => {
  return sendRequest(endpoint.admin.OUTLET, 'POST', payload);
};
export const getOutletServices = (payload) => {
  return sendRequest(endpoint.admin.OUTLET, 'GET', payload);
};
export const deleteOutletServices = (id) => {
  return sendRequest(endpoint.admin.OUTLET + '/' + id, 'DELETE');
};
