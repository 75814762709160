import React from "react";
import { Outlet } from "react-router-dom";
import Superadminsidebar from "./Superadminsidebar";
import { Navbar, Container } from "react-bootstrap";


const SuperAdminLayout = () => {


    return (
        <>
            <div className="Super-admin-layout DashboardPageLayout">
                <Superadminsidebar />
                <div className="mainwrapper">
                <Navbar expand="false" className="bg-body-tertiary navmob">
                            <Container fluid>
                                <Navbar.Brand href="#home">
                                    <img
                                        src="images/logo.png"
                                        width="auto"
                                        height="auto"
                                        className="d-inline-block align-top"
                                        alt="logo"
                                    />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} onClick={()=>document.body.classList.toggle("sidebaractive")} />
                            </Container>
                        </Navbar>
                    <div className="padding-admin">
                        <Outlet/>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SuperAdminLayout;