import { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDriverList } from "../../Services/admin-service";
import ViewDocumentButton from "../ViewDocumentButton";
import { ToastContainer, toast } from 'react-toastify'
import { InputGroup, Form } from 'react-bootstrap'
import {  AiOutlineSearch  } from 'react-icons/ai'
import 'react-toastify/dist/ReactToastify.css';

const DriverList = (props) => {

    const [currentPage, setcurrentPage] = useState(1);
    const [search, setSearch] = useState("")
    const [driversList, setDriversList] = useState([])
    const [totalPages, setTotalPages] = useState(0)

    useEffect(() => {
        loadData(currentPage, search);
    }, [currentPage, search])

    const loadData = (currentPage, search) => {
        getDriverList(currentPage, { search })
            .then((res) => {
                setDriversList(res.data.list)
                setTotalPages(res.data.paginationInfo.totalPages)
                setcurrentPage(res.data.paginationInfo.currentPage)
            }).catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                console.log(err)
            })
    }

    const renderActions = (rowData) => {
        return (
            <div>
                <ViewDocumentButton driverId={rowData._id} />
            </div>
        );
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0) {
            setcurrentPage(newPage);
        }
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
        setcurrentPage(1)
    }

    return (
        <>
            <div className="serachbar-box">

                <ToastContainer />

                <div className='searchbar'>
            <InputGroup >
              <InputGroup.Text id="basic-addon1"><AiOutlineSearch /></InputGroup.Text>
              <Form.Control
                placeholder="Search Customers"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={search}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
                <button className="btn btn-secondary" onClick={handleSearch}>Search</button>
            </div>
            <DataTable value={driversList} tableStyle={{ width: '100%' }}>
                <Column field="firstName" header="Name"></Column>
                <Column field="mobileNumber" header="Phone Number"></Column>
                <Column header="Action" body={renderActions}></Column>
            </DataTable>
            <div className="custom-pagination justify-content-between d-flex align-items-center py-4">
                <button className="btn btn-primary me-2" onClick={() => handlePageChange(+currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <span className="rounded-pill text-md">{`Page ${currentPage} of ${totalPages}`}</span>
                <button className="btn btn-primary ms-2" onClick={() => handlePageChange(+currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </>
    )
}

export default DriverList