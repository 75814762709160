import { useState, useEffect } from "react"
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";


import SelectDestination from "../Destination/SelectDestination"
import { addFair, getFairData, editFairData } from "../../Services/admin-service"


const AddFairForm = (props) => {

    const [toDest, setToDest] = useState(null)
    const [fromDest, setFromDest] = useState(null)
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('')
    const [carType, setCarType] = useState(null)
    const [fairType, setFairType] = useState('CtoA')
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const carTypeOption = [{ value: "regular", label: "Regular" }, { value: "premium", label: "Premium" }, { value: "suv", label: "SUV" }]

    const params = useParams();
    useEffect(() => {
        if (params.id) {
            const fairId = params.id
            const payload = {
                fairId
            }
            setIsEdit(true)
            getFairData(payload)
                .then(res => {
                    const data = res.data.data[0]
                    setToDest(data.toDestinationId.locationName)
                    setFromDest(data.fromDestinationId.locationName)
                    setPrice(data.amount)
                    setDiscount(data.discount)
                    setCarType(data.type)
                    if (data.toDestinationId.type == "city")
                        setFairType('CtoA')
                    else
                        setFairType('AtoC')

                    console.log(res)
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setIsEdit(false)
            setToDest(null)
            setFromDest(null)
            setPrice('')
            setDiscount('')
            setCarType(null)
            setFairType('CtoA')
            setIsSubmitted(false)
        }
        console.log(params.id, isEdit)
    }, [params.id, isEdit])


    const handleClickType = (e) => {
        setIsSubmitted(false)
        setCarType(e)
    }

    const handleSubmit = () => {
        if (isEdit) {
            if (!price || !discount) {
                toast.error("Kindly Fill All Fields", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                return false;
            }
            const payload = {
                fairId:params.id,
                amount: price,
                discount: discount
            }
            editFairData(payload)
                .then(res => {
                    
                    toast.success("Fair Edited", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(err => {
                    console.log(err)
                    toast.error(err.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })

        } else {
            if (!toDest || !fromDest || !price || !discount || !carType.value) {
                toast.error("Kindly Fill All Fields", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                return false;
            }

            const payload = {
                fromDestinationId: fromDest,
                toDestinationId: toDest,
                type: carType.value,
                amount: price,
                discount: discount
            }
            addFair(payload)
                .then((res) => {
                    setToDest(null)
                    setFromDest(null)
                    setPrice('')
                    setDiscount('')
                    setCarType(null)
                    setIsSubmitted(true)
                    toast.success("Fair Added", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
        }
    }

    const handleTypeChange = (e) => {
        setIsSubmitted(false)
        setFairType(e.target.value)
    }

    return (
        <>
            <ToastContainer />
            {!isEdit && <div className="bg-light p-3 border-l d-flex mt-4">
                <label className="ms-2 d-flex align-items-center"><input className="ms-2" type="radio" onClick={handleTypeChange} name="fairType" id="CtoA" value="CtoA" defaultChecked />&nbsp; From City To Airport</label>
                <label className="ms-3 d-flex align-items-center"><input className="ms-2" type="radio" onClick={handleTypeChange} name="fairType" id="AtoC" value="AtoC" />&nbsp; From Airport To City</label>
            </div>}
            <div className="">
                <div className="d-flex w-100 align-items-center py-2">
                    <div className="flex-md-fill">
                    <p className="mb-2">From</p>
                    {isEdit ? fromDest : <SelectDestination  onClickSelect={setFromDest} formSubmitted={isSubmitted} destVal={fromDest} destType={fairType == 'CtoA' ? "city" : "airport"} />}
                    </div>
                    <div className="flex-md-fill ms-3">
                    <p className="mb-2" >To</p>
                    {isEdit ? toDest : <SelectDestination onClickSelect={setToDest} formSubmitted={isSubmitted} destVal={toDest} destType={fairType == 'AtoC' ? "city" : "airport"} />}</div>
                </div>
                <div className="d-flex w-100 align-items-center py-2 ">
                <div className="flex-md-fill d-flex flex-column"><label className="mb-2">Price: </label> <input className="p-2 border-l" onChange={(e) => { setPrice(e.target.value); setIsSubmitted(false) }} value={price} type="number" /></div>
                <div className="flex-md-fill d-flex flex-column ms-3"><label className="mb-2">Discount(%): </label> <input className="p-2 border-l" onChange={(e) => { setDiscount(e.target.value); setIsSubmitted(false) }} value={discount} type="number" /></div></div>
                <div>
                    <p className="mb-2">Car Type:</p>
                    {isEdit ? carType : <Select value={carType} onChange={handleClickType} options={carTypeOption} />}
                </div>
                <button className="btn btn-primary my-3" onClick={handleSubmit}>Submit</button>
            </div>
        </>
    )
}

export default AddFairForm