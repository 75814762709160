import React from 'react';
import OutletList from '../../../Components/Outlet/OutletList';
import BackButton from '../../../Components/BackToPrev';

const OutletListing = () => {
  return (
    <div>
      <div className="top-header">
        <div className="page-name">
          <BackButton />
          <h4> Outlet Listing</h4>
        </div>
      </div>
     
      <OutletList />
    </div>
  );
};

export default OutletListing;
