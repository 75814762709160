import React from "react";
import { deleteDestination } from "../../Services/admin-service";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const DeleteDestination = (props) => {

    const destinationId = props.destId

    const handleClick = () => {

        const payload = {
            destinationId
        }

        deleteDestination(payload)
            .then((res) => {
                const nowDocs = props.listVal.filter(val => val._id != destinationId)
                props.onClickDelete(nowDocs)
                toast.success("Destination Deleted", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

            })
    }

    return (
        <>
            <ToastContainer />
            <button className="btn btn-danger" onClick={handleClick}>Delete</button>
        </>
    )
}

export default DeleteDestination