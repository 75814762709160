import BackButton from '../../../Components/BackToPrev'
import FairList from '../../../Components/Fair/FairList'


const FairListing = (props) => {

    const fairListing = [{to:"A",from:"b",price:"123",discount:"10",carType:"SUV"},{to:"v",from:"d",price:"14",discount:"16",carType:"SUV"}]

    return (
        <>
            <div className=''>
                <div className='top-header'>
                    <div className='page-name'>
                        <BackButton />
                        <h4>Fair Listing</h4>
                    </div>
                </div>
                <FairList />
            </div>
        </>
    )
}

export default FairListing