import React from "react";
import { useNavigate } from "react-router-dom";

const FairListButton = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/fairListing`, { replace: true });
    }

    return (
        <>
        <button onClick={handleClick}>Fair Listing</button>
        </>
    )
}

export default FairListButton