export const endpoint = {
  auth: {
    LOGIN: 'admin/auth/login',
  },
  admin: {
    GET_DRIVERS_LIST: 'admin/profile/driver-list?currentPage=',
    GET_DRIVER_DOCS: 'admin/document/drivers-document',
    VERIFY_DRIVER_DOC: 'admin/document/accept-reject-document',
    ADD_DESTINATION: 'admin/location/add-destination',
    GET_CITY_LISTING: 'admin/location/get-destination-list',
    DELETE_DESTINATION: 'admin/location/delete-destination',
    ADD_FAIR: 'admin/location/add-fair',
    GET_FAIR_LIST: 'admin/location/fair-list',
    DELETE_FAIR: 'admin/location/delete-fair',
    GET_FAIR_DATA: 'admin/location/fair-data',
    EDIT_FAIR_DATA: 'admin/location/edit-fair',
    WITHDRAWAL_LIST: 'payment/withdrawal-list',
    ACCEPT_WITHDRAWAL: 'payment/withdrawal-completed',
    REJECT_WITHDRAWAL: 'payment/reject-withdrawal',
    OUTLET: 'admin/outlet',
  },
};
